function initMap() {
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 10,
    center: { lat: 35.679339, lng: 139.863221 },
    mapTypeId: 'terrain'
  });

  var citymap = {
    a: {
      center: { lat: 35.679339, lng: 139.863221 },
      radius: 16000
    }
  };

  for (var city in citymap) {
    var cityCircle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#FF0000',
      fillOpacity: 0.30,
      map: map,
      center: citymap[city].center,
      radius: citymap[city].radius
    });
  }
}

if ((typeof google) !== 'undefined') {
  initMap();
}
