/**
 * モバイル端末なら true 、それ以外なら false を返す
 *
 * @returns Boolean
 */
const isMobile = () => {
  const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  return (window.navigator.userAgent.search(regexp) !== -1);
};

const confirm = (e) => {
  if (!isMobile()) {
    e.preventDefault();
  }
};

const tels = document.getElementsByClassName('telLink');

for (let i = 0, l = tels.length; i < l; i++) {
  if (!isMobile()) {
    tels[i].style.cursor = 'text';
  }
  tels[i].addEventListener('click', confirm, false);
}
var i = 0;
