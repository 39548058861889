let doit = null;

const adjust = () => {
  const eqHeights = document.getElementsByClassName('eqHeight');
  const heights = [];
  const wW = window.innerWidth;

  clearTimeout(doit);

  doit = setTimeout(() => {
    const setEqHeights = () => {
      const reset = () => {
        for (let i = 0, l = eqHeights.length; i < l; i++) {
          eqHeights[i].removeAttribute('style');
        }
      }

      if (wW < 767) {
        reset(); 

        for (let i = 0, l = eqHeights.length; i < l; i++) {
          heights.push(eqHeights[i].clientHeight);
        }

        for (let i = 0, l = eqHeights.length; i < l; i++) {
          eqHeights[i].style.height = Math.max.apply(null, heights) + 'px';
        }
      } else {
        reset(); 
      }
    };

    setEqHeights();
  }, 200);
};

window.addEventListener('DOMContentLoaded', adjust, false);
window.addEventListener('resize', adjust, false);