import '@babel/polyfill';

// jQuery
// import $ from 'jquery';

// Jsモジュール
/* eslint-disable */
import { eqHeight } from './modules/eqHeight';
import { ScrollTop } from './modules/scrollTop';
import { telLink } from './modules/telLink';
import { initMap } from './modules/initMap';
import { isMobile } from './modules/isMobile';
/* eslint-disable */
