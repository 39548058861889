let start = null;
const scrollTop = document.getElementById('scrollTop');

/**
 * トップに戻る
 * @param number currentTime 経過時間
 * @param number startValue  初期値
 * @param number changeValue 初期値からの変化量
 * @param number duration    処理時間
 * @returns number 変化量
 */
const easingEaseOutExpo = function (currentTime, startValue, changeValue, duration) {
  return changeValue * (-1 * Math.pow(2, -10 * currentTime / duration) + 1) + startValue;
};

const scrollTopAnim = () => {
  const scrollY = window.scrollY;

  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;

    // progress(timestamp, start)はミリ秒
    window.scrollTo(0, easingEaseOutExpo(progress / 1000, scrollY, -scrollY, .5));
    if (progress < 1 * 1000) {
      window.requestAnimationFrame(step);
    } else {
      start = null;
    }
  }

  window.requestAnimationFrame(step);
};

let doit = null;

const scrollTopAdjust = () => {
  const pageYOffset = window.pageYOffset;

  clearTimeout(doit);

  doit = setTimeout(() => {
    if (pageYOffset > 100) {
      scrollTop.classList.remove('fade-out');
      scrollTop.classList.add('fade-in');
    } else {
      scrollTop.classList.remove('fade-in');
      scrollTop.classList.add('fade-out');
    }
  }, 200);
};

scrollTop.addEventListener('click', scrollTopAnim, false);
window.addEventListener('scroll', scrollTopAdjust, false);